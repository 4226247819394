body {
  font-family: Raleway, sans-serif;
  color: #000;
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 0.05em;
}

h1 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 31px;
  line-height: 45px;
  font-weight: 400;
  text-transform: uppercase;
}

h2 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 31px;
  line-height: 45px;
  font-weight: 400;
  text-transform: uppercase;
}

h3 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 22px;
  line-height: 25px;
  font-weight: 500;
  text-transform: uppercase;
}

p {
  margin-bottom: 25px;
}

.header {
  position: relative;
  z-index: 999;
  width: 100%;
  padding-right: 24px;
  padding-left: 5.4%;
  background-color: #fff;
}

.brand {
  margin-top: 0;
  margin-bottom: 0;
}

.logo {
  display: block;
  width  : auto;
  height : 26px;
}

.nav-action {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.header-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.menu-button {
  padding: 20px;
  cursor: pointer;
}

.socialmedia {
  margin-right: 20px;
  margin-left: 20px;
}

.icon-messenger {
  width: 19px;
  height: auto;
}

.hamburger {
  position: relative;
  width: 16px;
  height: 14px;
}

.line {
  width: 16px;
  height: 2px;
  margin-top: 3px;
  margin-bottom: 3px;
  background-color: #a200ff;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.line.last {
  width: 8px;
}

.hero {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  min-height: 940px;
}

.hero.hero-medium {
  height: 702px;
  min-height: 650px;
}

.img-object-fit {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}

.img-object-fit.opacity {
  opacity: 0.6;
}

.hero-slider {
  height: 100%;
  background-color: transparent;
}

.hero-logo {
  position: absolute;
  z-index: 3;
  width: 266px;
  height: auto;
  min-height: 10px;
  margin-top: 74px;
  margin-left: 5.4%;
}

.hero-slider-caption {
  position: absolute;
  bottom: 70px;
  z-index: 1;
  width: 50%;
  min-height: 10px;
  padding-left: 5.4%;
  color: #fff;
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.hero-logo-img {
  width: 100%;
}

.hero-slider-nav {
  bottom: 25px;
  padding-left: 5.4%;
  text-align: left;
}

.hero-slider-nav.hero-slider-nav-inner {
  display: none;
}

.section {
  padding: 50px 5.4%;
}

.section.bg-gray {
  background-image: linear-gradient(103deg, #efefef, #ebebeb);
}

.section.bg-gray.section-extra-padding {
  padding: 35px 15.4% 5px;
}

.section.bg-blue {
  background-image: linear-gradient(96deg, #005efd, #00377f);
  color: #fff;
}

.section.bg-blue.padding-min {
  padding-top: 35px;
  padding-bottom: 35px;
}

.section.section-padding-zero {
  padding-right: 0%;
  padding-left: 0%;
}

.section.bg-light {
  background-color: #f7f7f7;
}

.section.bg-light.section-extra-padding {
  padding: 35px 15.4% 5px;
}

.section.bg-gradient-blue {
  background-image: linear-gradient(291deg, #009cde, #004e6f);
  color: #fff;
}

.section.bg-gradient-blue.section-extra-padding {
  padding: 35px 15.4%;
}

.section.section-mobile {
  display: none;
}

.full-container {
  position: relative;
  display: block;
  max-width: 1696px;
  margin-right: auto;
  margin-left: auto;
}

.full-container.flex-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.full-container.text-center {
  text-align: center;
}

.main-container.middle-container {
  width: 50%;
  min-height: 10px;
}

.main-container.middle-container.absolute-container {
  position: absolute;
  z-index: 3;
  width: 40%;
}

.main-container.middle-container.micro-container {
  margin-bottom: 50px;
}

.main-container.third-container {
  width: 60%;
}

.services-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -10px;
  margin-left: -10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.services-item {
  position: relative;
  width: 25%;
  padding-right: 10px;
  padding-left: 10px;
}

.block {
  position: relative;
}

.services-img {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 500px;
}

.services-caption {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, .6);
  opacity: 0;
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  color: #fff;
}

.services-caption.family-style {
  background-image: url('../images/family-lisfestyle-icon.svg');
  background-position: 139% 50%;
  background-size: auto 215px;
}

.button {
  padding: 7px 50px;
  border-radius: 3px;
  background-image: linear-gradient(99deg, #9e82de, #b253ab);
  color: #fff;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.1em;
  text-decoration: none;
  text-transform: uppercase;
}

.button.button-fat {
  padding: 20px;
  background-color: #be0dff;
  background-image: none;
  font-size: 14px;
  font-weight: 700;
}

.button.newsletter-button {
  padding: 9px 70px;
  border: 1px solid #000;
  background-color: transparent;
  background-image: none;
  color: #000;
  line-height: 12px;
  font-weight: 700;
}

.button.button-transparent {
  border: 1px solid #fff;
  background-color: transparent;
  background-image: none;
}

.button.button-transparent.button-blog {
  margin-top: 10px;
}

.button.micro-button {
  width: 100%;
  margin-bottom: 20px;
  padding-right: 40px;
  padding-left: 40px;
  font-size: 12px;
}

.button.micro-button.micro-button-secondary {
  background-color: #acacac;
  background-image: none;
}

.testimonial-slider {
  height: auto;
  background-color: transparent;
}

.testimonial-slider-nav {
  text-align: left;
}

.testimonial-description {
  position: relative;
  width: 50%;
  min-height: 10px;
  margin-left: auto;
  padding-right: 10%;
  padding-left: 10%;
  font-family: 'Cormorant Infant', sans-serif;
  font-size: 29px;
  line-height: 43px;
  font-style: italic;
  letter-spacing: 0em;
}

.testimonial-author {
  padding-left: 27px;
  border-left: 2px solid #a200ff;
  font-family: Raleway, sans-serif;
  font-size: 15px;
  line-height: 30px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.05em;
}

._40-container {
  width: 40%;
  padding-right: 5%;
}

.step-services-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.step-service {
  width: 25%;
  padding-right: 25px;
  padding-left: 25px;
  border-left: 4px solid #a200ff;
}

.step-number {
  display: block;
  margin-top: -10px;
  margin-bottom: 5px;
  font-size: 48px;
  line-height: 48px;
  font-weight: 300;
  letter-spacing: 0.03em;
}

.heading {
  margin-top: 0px;
}

.h2-padding {
  display: inline-block;
  margin-right: 50px;
  margin-bottom: 0px;
  padding-top: 25px;
  padding-right: 50px;
  padding-bottom: 25px;
  border-right: 4px solid #fff;
  font-size: 28px;
}

.footer {
  padding: 60px 5.4% 10px;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.1em;
}

.footer.micro-footer {
  padding-top: 35px;
  padding-left: 1.4000000000000004%;
}

.footer-logo {
  width: 266px;
  height: auto;
  margin-bottom: 15px;
}

.footer-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 10px;
  margin-bottom: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.footer-flex.micro-footer-flex {
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-bottom-style: none;
}

.footer-30 {
  width: 29%;
  padding-right: 70px;
}

.footer-30.right {
  padding-right: 0px;
  padding-left: 30px;
}

.footer-25 {
  width: 28%;
  padding-right: 30px;
  padding-left: 30px;
}

.footer-25._2-columns {
  -webkit-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 35px;
  column-gap: 35px;
}

.footer-15 {
  width: 14%;
  padding-right: 30px;
  padding-left: 30px;
}

.footer-link {
  display: block;
  color: #000;
  text-decoration: none;
}

.newsletter-label {
  font-weight: 400;
}

.newsletter-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.newsletter-input {
  margin-right: 10px;
  margin-bottom: 0px;
  border-style: solid;
  border-width: 0px 0px 1px;
  border-bottom-color: #000;
}

.footer-social {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.social-icon {
  position: relative;
  width: 16px;
  height: 16px;
  margin-right: 20px;
}

.full-width {
  display: block;
  width: 100%;
  height: auto;
}

.navigation {
  position: fixed;
  right: 0px;
  z-index: 998;
  overflow: auto;
  width: 400px;
  height: auto;
  min-height: 100vh;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: rgba(0, 0, 0, .54);
  -webkit-transform: translate(100%, 0px);
  -ms-transform: translate(100%, 0px);
  transform: translate(100%, 0px);
  -webkit-transition: all 400ms ease-in-out;
  transition: all 400ms ease-in-out;
  color: #fff;
  text-align: right;
}

.navigation.active {
  background-color: rgba(0, 0, 0, .74);
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.nav-link {
  display: block;
  padding: 20px 40px;
  color: #fff;
  font-size: 17px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-decoration: none;
  text-transform: uppercase;
}

.nav-link.nav-language {
  border-top: 1px solid #fff;
}

.nav-link.sub-nav-link {
  padding-top: 25px;
  padding-bottom: 25px;
  color: #000;
}

.nav-link-label {
  display: inline-block;
}

.icon-nav-link {
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.icon-nav-link.active {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sub-nav {
  overflow: hidden;
  max-height: 1000px;
  background-color: #fff;
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
  color: #000;
}

.sub-nav.hide {
  max-height: 0px;
}

.nav-line {
  display: block;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  border-top: 1px solid #fff;
}

.hero-min {
  position: relative;
  max-height: 260px;
  padding: 50px 5.4% 30px;
  background-image: linear-gradient(98deg, rgba(80, 80, 80, .9), rgba(50, 25, 187, .9)), url('../images/NoPath---copia-23.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  color: #fff;
}

.hero-min.hero-min-blog {
  background-image: linear-gradient(98deg, rgba(72, 72, 72, .9), rgba(0, 0, 0, .9));
}

.hero-min.faqs {
  background-image: linear-gradient(98deg, rgba(80, 80, 80, .9), rgba(187, 95, 25, .9)), url('../images/3f266f0ee04d65b59a238da7cf5422c9.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.breadcrumbs {
  position: relative;
  margin-bottom: 30px;
  font-size: 13px;
  text-transform: uppercase;
}

.breadcrumbs.breadcrumbs-blog {
  margin-bottom: 10px;
}

.breadcrumb-item {
  position: relative;
  margin-right: 10px;
  padding-right: 10px;
  color: #fff;
  text-decoration: none;
}

.inner-h1 {
  font-size: 40px;
  line-height: 50px;
}

.quarter-container {
  width: 85%;
  min-height: 20px;
}

.quarter-container.two-columns {
  -webkit-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 3em;
  column-gap: 3em;
}

.step-heading {
  margin-bottom: 10px;
}

.section-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.section-left {
  width: 50%;
  padding: 50px 4.5% 50px 5.4%;
  background-color: #f8f8f8;
}

.section-right {
  width: 50%;
  padding: 50px 5.4%;
  background-color: #ebebeb;
}

.form-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.radio-button-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 77px;
  margin-bottom: 13px;
  padding-left: 0px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.form-title {
  margin-bottom: 50px;
  font-size: 31px;
  line-height: 45px;
  font-weight: 500;
  letter-spacing: 0em;
}

.form-col-wrapper {
  display: block;
  width: 75%;
  margin-right: auto;
  margin-left: auto;
}

.radio-image-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background-color: #000;
}

.radio-image-wrapper.micro-radio-image-wrapper {
  width: 100%;
  height: 100%;
}

.radio-image-wrapper.micro-radio-image-wrapper.inactive {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.radio-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 15px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsla(0, 0%, 92%, .66);
  color: #000;
  font-size: 11px;
  line-height: 32px;
  letter-spacing: 0.1em;
  text-decoration: none;
  text-transform: uppercase;
}

.radio-image {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.radio-image.micro-radio-image {
  opacity: 1;
}

.radio-image.micro-radio-image.inactive {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.radio-icon {
  position: relative;
  z-index: 1;
  width: 80px;
  height: 53px;
  margin-right: 15px;
  margin-left: 15px;
}

.radio-text {
  position: relative;
  z-index: 1;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #fff;
  font-size: 14px;
  line-height: 31px;
  letter-spacing: 0.1em;
}

.radio-circle {
  position: relative;
  z-index: 1;
  width: 17px;
  height: 17px;
  margin-right: 30px;
  margin-left: 15px;
  border: 2px solid #fff;
  border-radius: 50%;
}

.radio-circle.micro-radio-circle {
  position: absolute;
  top: 15px;
  right: 15px;
  margin-right: 0px;
  margin-left: 0px;
}

.radio-label {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 2;
  width: 80%;
  height: 100%;
  color: transparent;
}

.radio-label.micro-radio-label {
  right: 0px;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.form-input {
  position: relative;
  z-index: 1;
  margin-bottom: 0px;
  padding: 5px 20px;
  border-style: none none solid;
  border-bottom-width: 1px;
  border-bottom-color: #000;
  background-color: transparent;
  color: #005efd;
  font-weight: 600;
}

.form-input:focus {
  border-style: none none solid;
  border-bottom-color: #005efd;
}

.form-input.form-date {
  padding-right: 40px;
  background-image: url('../images/calendar-icon.svg');
  background-position: 98% 50%;
  background-size: 18px;
  background-repeat: no-repeat;
}

.form-label-wrapper {
  position: relative;
  height: 38px;
  margin-bottom: 50px;
}

.form-label {
  position: absolute;
  left: 20px;
  top: auto;
  bottom: 0px;
  z-index: 0;
  margin-bottom: 0px;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  font-size: 13px;
  line-height: 24px;
  font-style: italic;
  font-weight: 400;
}

.form-label.active {
  left: 0px;
  top: -50%;
  bottom: auto;
  color: #005efd;
  font-style: normal;
}

.acceptance-wrapper {
  margin-top: 90px;
  margin-bottom: 15px;
}

.acceptance-label {
  margin-bottom: 0px;
  font-size: 12px;
  line-height: 24px;
}

.acceptance-checkbox {
  margin-top: 10px;
}

.acceptance-checkbox ~ .wpcf7-list-item-label{
  font-weight: 400;
  margin-bottom: 0px;
  font-size: 12px;
  line-height: 24px;
}

.form-submit-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.form-submit-wrapper p {
  margin-bottom: 0;
}
.form-recaptcha {
  margin-bottom: 0px;
}

.success-message {
  position: fixed;
  bottom: 0px;
  z-index: 99;
  width: 100%;
  padding: 60px 5.4%;
  background-image: linear-gradient(94deg, #005efd, #00377f);
  -webkit-transform: translate(0px, 100%);
  -ms-transform: translate(0px, 100%);
  transform: translate(0px, 100%);
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
  color: #fff;
  font-size: 22px;
  line-height: 25px;
  text-align: center;
}

.success-message.active {
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  text-transform: uppercase;
  cursor: pointer;
}

.hero-overlay {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  background-image: linear-gradient(119deg, rgba(0, 55, 127, .48), rgba(0, 94, 253, .48));
}

.hero-overlay.videography {
  background-image: linear-gradient(119deg, rgba(0, 55, 127, .48), rgba(255, 0, 0, .48));
}

.hero-overlay.about {
  background-image: linear-gradient(119deg, hsla(0, 0%, 51%, .9), rgba(0, 0, 0, .9));
}

.hero-overlay.blog {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .3)), to(rgba(0, 0, 0, .3)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .3));
}

.hero-data {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  padding: 50px 5.4% 25px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-image: url('../images/fullday-family-icon.svg');
  background-position: left -170px top 160px;
  background-size: 340px 340px;
  min-height: 940px;
  background-repeat: no-repeat;
  color: #fff;
}

.hero-data.videography {
  background-image: url('../images/videostories-icon.svg');
}

.hero-data.about {
  background-image: none;
}

.hero-data.hero-blog-detail {
  background-image: none;
}

.hero-data-bottom {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.hero-data-bottom.about {
  width: 100%;
}

.main-photo {
  margin-bottom: 50px;
  text-align: right;
}

.img-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 446px;
  background-color: #000;
}

.img-wrapper.img-wrapper-list {
  height: 471px;
}

.img-wrapper.img-wrapper-list.vdeography {
  background-color: #000;
}

.img-title {
  margin-bottom: 15px;
}

.photo-wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -10px;
  margin-left: -10px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.photo-wrapper.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.photo-item {
  width: 50%;
  margin-bottom: 50px;
  padding-right: 10px;
  padding-left: 10px;
  text-align: right;
}

.play-button {
  position: relative;
  top: 50%;
  display: block;
  width: 48px;
  height: 48px;
  margin-right: auto;
  margin-left: auto;
  opacity: 0;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}

.about-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.text-2-columns {
  -webkit-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 3em;
  column-gap: 3em;
}

.owlstory-symbol {
  padding-left: 20px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.about-slider {
  height: auto;
  background-color: transparent;
}

.about-slide-item {
  width: 25%;
  padding-right: 10px;
  padding-left: 10px;
}

.about-slider-nav {
  bottom: -50px;
}

.form-block {
  margin-bottom: 0px;
}

.blog-wrapper {
  overflow: hidden;
  max-height: 715px;
  margin-bottom: 50px;
  padding-left: 0px;
}

.blog-wrapper.cs-hidden {
  overflow: hidden;
  max-height: 715px;
}

.blog-wrapper.blog-related-wrapper {
  max-height: 250px;
}

.blog-list {
  position: relative;
  width: 285px;
  opacity: 0.2;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.blog-list.blog-list-related {
  width: 380px;
}

.blog-contain {
  min-height: 20px;
  padding-right: 10px;
  padding-left: 10px;
}

.blog-data {
  margin-bottom: 15px;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  font-size: 0.5em;
}

.blog-image {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 450px;
  margin-bottom: 78px;
}

.blog-image.blog-reated-image {
  height: 142px;
}

.blog-desc {
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.blog-caption {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, .66);
  opacity: 0;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  color: #fff;
}

.blog-caption.blog-caption-related {
  padding: 20px;
}

.main-blog {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.addthis-wrapper {
  position: relative;
  min-height: 25px;
  padding-left: 15px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.comments-wrapper {
  position: relative;
  min-height: 50px;
}

.section-related-blog {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
}

.blog-related-title {
  font-size: 20px;
  letter-spacing: 0.03em;
  text-transform: none;
}

.rich-text.text-center {
  text-align: center;
}

.flex-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flex-container.flex-header-payment {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.micro-nav {
  position: relative;
  overflow: hidden;
  width: 330px;
  height: 100vh;
}

.micro-nav.micro-nav-payment {
  width: 710px;
}

.micro-container {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.micro-nav-container {
  position: relative;
  top: 0px;
  width: 100%;
  height: 100%;
  padding: 35px 35px 60px;
  text-align: center;
}

.micro-logo {
  width: 132px;
  height: auto;
  margin-bottom: 95px;
}

.micro-h3 {
  margin-bottom: 20px;
  text-align: left;
  text-transform: none;
}

.micro-hero {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 758px;
}

.h2-lower {
  font-weight: 500;
  text-transform: none;
}

.micro-buy-photos {
  position: relative;
  display: block;
  width: 100%;
  min-height: 20px;
  margin-top: 40px;
  margin-right: -15px;
  margin-left: -15px;
  padding-right: 40px;
  padding-left: 40px;
}

.micro-form-buy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.micro-radio {
  position: relative;
  width: 166px;
  height: 166px;
  margin-bottom: 30px;
  padding-right: 15px;
  padding-left: 15px;
}

.button-icon {
  width: 16px;
  height: auto;
  margin-right: 5px;
}

.button-text {
  display: inline-block;
}

.checkout-wrapper {
  position: absolute;
  bottom: 0px;
  width: 100%;
  min-height: 500px;
  padding: 30px 35px;
  background-image: linear-gradient(330deg, #009cde, #004e6f);
  -webkit-transform: translate(0px, 85%);
  -ms-transform: translate(0px, 85%);
  transform: translate(0px, 85%);
  -webkit-transition: all 400ms linear;
  transition: all 400ms linear;
  color: #fff;
  font-size: 13px;
  line-height: 23px;
}

.checkout-wrapper.active {
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
}

.checkout-title {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  letter-spacing: 0.03em;
  cursor: pointer;
}

.paypal-wrapper {
  min-height: 50px;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #707070;
  border-radius: 8px;
  background-color: #fff;
  color: #000;
  font-size: 12px;
  line-height: 23px;
  text-align: center;
}

.paypal-title {
  margin-bottom: 38px;
  font-size: 22px;
  line-height: 25px;
  font-weight: 500;
  text-align: center;
}

.paypal-price {
  font-size: 13px;
}

.paypal-price.total {
  color: #009cde;
}

.paypal-bold {
  color: #009cde;
  font-size: 13px;
  font-weight: 700;
}

.paypal-button {
  min-height: 50px;
  margin-top: 15px;
}

.paypal-desc {
  padding-right: 10px;
  padding-left: 10px;
}

.paypal-text {
  margin-bottom: 8px;
  text-align: left;
}

.payment-logo {
  width: 193px;
  height: auto;
}

.payment-header-text {
  text-align: right;
}

.payment-data {
  margin-bottom: 30px;
  padding-left: 18%;
}

.payment-data-bold {
  font-style: italic;
  font-weight: 600;
}

.payment-checkout-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.payment-form-block {
  margin-bottom: 0px;
}

.form-checkout-checkbox {
  margin-right: 5px;
}

.form-payment-button {
  position: relative;
  margin-left: 10px;
}

.checkout-table {
  margin-top: 15px;
  margin-bottom: 60px;
}

.services-icon {
  position: absolute;
  left: 0;
  top: 15%;
  height: 35%;
  transform: translateX(-50%);
}

.footer-mobile-nav {
  display: none;
}

.navigation-hidden {
  overflow: auto;
  height: 85vh;
}

.micro-logo-mobile {
  display: none;
}

.payment-general-paypal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hero-slider-overlay {
  display: none;
}

@media (max-width: 1024px) {
  h1 {
    font-size: 20px;
    line-height: 30px;
  }
  h2 {
    font-size: 20px;
    line-height: 30px;
  }
  h3 {
    font-size: 19px;
  }
  .hero-logo {
    bottom: 200px;
    width: 170px;
    margin-top: 0px;
  }
  .hero-slider-caption {
    width: 70%;
    padding-right: 5.4%;
    font-size: 25px;
    line-height: 30px;
  }
  .section.bg-gray.section-extra-padding {
    padding-right: 5.4%;
    padding-left: 5.4%;
  }
  .section.bg-light.section-extra-padding {
    padding-right: 5.4%;
    padding-left: 5.4%;
  }
  .section.bg-gradient-blue.section-extra-padding {
    padding-right: 5.4%;
    padding-left: 5.4%;
  }
  .full-container.flex-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .full-container.text-center {
    text-align: left;
  }
  .main-container.middle-container.absolute-container {
    position: relative;
    width: 100%;
  }
  .main-container.middle-container.micro-container {
    width: 100%;
  }
  .main-container.third-container {
    width: 75%;
  }
  .services-item {
    width: 50%;
  }
  .button.newsletter-button {
    width: 100%;
  }
  .button.micro-button {
    padding-right: 25px;
    padding-left: 25px;
  }
  .testimonial-slider-nav {
    bottom: -50px;
  }
  .testimonial-description {
    width: 100%;
    font-size: 22px;
    line-height: 36px;
  }
  .testimonial-author {
    font-size: 14px;
  }
  ._40-container {
    width: 100%;
    padding-right: 0%;
  }
  .step-service {
    width: 50%;
    margin-bottom: 30px;
  }
  .h2-padding {
    border-right-style: none;
  }
  .footer-30 {
    width: 65%;
  }
  .footer-30.right {
    width: 35%;
  }
  .footer-25._2-columns {
    width: 65%;
    padding-left: 0px;
  }
  .footer-15 {
    width: 35%;
  }
  .newsletter-flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .newsletter-input {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .breadcrumbs {
    display: none;
  }
  .inner-h1 {
    font-size: 25px;
    line-height: 30px;
  }
  .quarter-container {
    width: 100%;
  }
  .quarter-container.two-columns {
    -webkit-column-count: 1;
    column-count: 1;
  }
  .step-heading {
    font-size: 19px;
  }
  .section-left {
    width: 100%;
  }
  .section-right {
    width: 100%;
  }
  .form-flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .form-title {
    font-size: 25px;
    line-height: 35px;
  }
  .radio-label.micro-radio-label {
    width: 100%;
  }
  .success-message.active {
    font-size: 19px;
  }
  .hero {
    min-height: 720px;
  }
  .hero-data {
    background-position: left -150px top 140px;
    background-size: 300px 300px;
    min-height: 720px;
  }
  .hero-data-bottom {
    width: 100%;
  }
  .img-wrapper {
    height: 250px;
  }
  .img-wrapper.img-wrapper-list {
    height: 220px;
  }
  .text-2-columns {
    -webkit-column-count: 1;
    column-count: 1;
  }
  .owlstory-symbol {
    display: none;
  }
  .about-slide-item {
    width: 50%;
  }
  .blog-wrapper {
    max-height: 760px;
  }
  .blog-contain {
    padding-right: 40px;
    padding-left: 40px;
  }
  .micro-nav {
    width: 230px;
  }
  .micro-nav.micro-nav-payment {
    width: 230px;
  }
  .micro-nav-container {
    padding-right: 20px;
    padding-left: 20px;
  }
  .micro-hero {
    height: 558px;
  }
  .micro-buy-photos {
    padding-right: 15px;
    padding-left: 15px;
  }
  .micro-form-buy {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .micro-radio {
    margin-right: 15px;
    margin-left: 15px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .checkout-wrapper {
    padding-right: 20px;
    padding-left: 20px;
  }
  .checkout-title {
    font-size: 18px;
  }
  .payment-logo {
    width: 160px;
  }
  .payment-data {
    padding-left: 10%;
  }
  .payment-checkout-wrapper {
    margin-top: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .form-payment-button {
    margin-top: 20px;
    margin-left: 0px;
  }
  .payment-general-paypal {
    margin-bottom: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  body {
    font-size: 14px;
    line-height: 29px;
  }
  .logo {
    height: 18px;
  }
  .hero {
    min-height: 500px;
  }
  .hero-logo {
    bottom: 230px;
    display: none;
  }
  .hero-slider-caption {
    z-index: 2;
  }
  .hero-slider-nav {
    bottom: 5px;
    text-align: center;
  }
  .section {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .section.bg-gray.section-testimonial {
    padding-bottom: 70px;
  }
  .section.section-mobile {
    display: block;
  }
  .main-container.third-container {
    width: 100%;
  }
  .services-item {
    width: 300px;
    margin-right: 5px;
    margin-left: 5px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
  }
  .services-img {
    height: 400px;
  }
  .button.micro-button {
    width: 43%;
    margin-right: 10px;
    margin-left: 10px;
  }
  .testimonial-slider-nav {
    text-align: center;
  }
  .testimonial-slider-item {
    margin-bottom: 30px;
  }
  .testimonial-description {
    padding-top: 45px;
    padding-right: 0%;
    padding-left: 0%;
  }
  .step-service {
    width: 100%;
  }
  .h2-padding {
    margin-right: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 20px;
  }
  .footer.micro-footer {
    padding-left: 5.4%;
  }
  .footer-30.right {
    width: 100%;
    padding-left: 0px;
  }
  .footer-15 {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .footer-link {
    display: block;
    margin-right: 35%;
  }
  .footer-social {
    position: absolute;
    top: 0px;
    right: 0px;
    margin-top: 10px;
  }
  .social-icon {
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }
  .navigation.active {
    width: 100%;
  }
  .radio-button-wrapper {
    width: 250px;
    height: auto;
    margin-right: 5px;
    margin-left: 5px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
  }
  .form-col-wrapper {
    width: 85%;
  }
  .radio-image-wrapper {
    height: 250px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .radio-icon {
    width: 109px;
    height: 67px;
    margin-bottom: 20px;
  }
  .radio-text {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .radio-circle {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 34px;
    height: 34px;
    margin-right: 0px;
    margin-left: 0px;
  }
  .radio-circle.micro-radio-circle {
    width: 17px;
    height: 17px;
  }
  .radio-label {
    width: 100%;
    height: 80%;
  }
  .radio-label.micro-radio-label {
    height: 100%;
  }
  .form-submit-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .form-recaptcha {
    margin-bottom: 20px;
  }
  .success-message.active {
    font-size: 14px;
    line-height: 29px;
  }
  .hero-data {
    min-height: 500px;
  }
  .main-photo {
    margin-bottom: 15px;
  }
  .img-wrapper {
    height: 195px;
  }
  .photo-wrapper {
    display: block;
    margin-right: 0px;
    margin-left: 0px;
  }
  .photo-item {
    width: 100%;
    margin-bottom: 15px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .about-slide-item {
    width: 100%;
  }
  .blog-wrapper.blog-related-wrapper {
    margin-bottom: 30px;
  }
  .blog-data {
    font-size: 1em;
  }
  .flex-container {
    display: block;
  }
  .flex-container.flex-header-payment {
    text-align: center;
  }
  .micro-nav {
    width: 100%;
    height: auto;
  }
  .micro-nav.micro-nav-payment {
    width: 100%;
    height: 185px;
  }
  .micro-nav-container {
    padding-right: 5.4%;
    padding-bottom: 5px;
    padding-left: 5.4%;
  }
  .micro-logo {
    display: none;
  }
  .micro-h3 {
    text-align: center;
  }
  .micro-hero {
    height: 185px;
  }
  .h2-lower.h2-payment {
    margin-bottom: 45px;
  }
  .checkout-wrapper {
    position: relative;
    bottom: auto;
    max-height: 80px;
    min-height: 0px;
    padding: 0px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .checkout-wrapper.active {
    z-index: 1;
    max-height: 600px;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
  .payment-logo {
    display: none;
  }
  .payment-header-text {
    margin-bottom: 20px;
    text-align: center;
  }
  .payment-data {
    margin-bottom: 20px;
    padding-left: 5%;
  }
  .payment-data-bold {
    display: block;
  }
  .payment-checkout-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form-payment-button {
    margin-top: 15px;
    margin-left: 0px;
  }
  .footer-mobile-nav {
    display: block;
    width: 100%;
    min-height: 50px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .hidden-mobile {
    display: none;
    width: 100%;
  }
  .micro-logo-mobile {
    display: block;
    width: 150px;
    height: auto;
    margin: 30px auto 35px;
  }
  .checkout-padding {
    padding: 30px 20px;
  }
  .payment-general-paypal {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .hero-slide-item {
    background-color: #000;
  }
  .hero-slider-overlay {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1;
    display: block;
    background-color: rgba(0, 0, 0, .3);
  }
}

@media (max-width: 479px) {
  .hero {
    height: 80vh;
    min-height: 0px;
  }
  .hero-slider-caption {
    width: 100%;
  }
  .button.micro-button {
    display: inline-block;
    width: 42%;
    padding-right: 10px;
    padding-left: 10px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .footer-link {
    margin-right: 0%;
  }
  .footer-social {
    z-index: 3;
  }
  .form-col-wrapper {
    width: 100%;
  }
  .hero-data {
    height: 80vh;
    min-height: 0px;
    background-position: left -140px top 140px;
    background-size: 280px 280px;
  }
  .blog-contain {
    padding-right: 20px;
    padding-left: 20px;
  }
  .micro-buy-photos {
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .micro-radio {
    width: 150px;
    height: 150px;
  }
  .button-text {
    width: 70%;
  }
}
