.w-webflow-badge{
  display: none!important;
}
.img-object-fit{
  object-fit: cover;
  object-position: 50% 50%;
}
.header{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.menu-button.is-active .line:nth-child(1),
.menu-button.is-active .line:nth-child(3){
  width: 10px;
}
.menu-button.is-active .line:nth-child(1){
  -webkit-transform: translateX(10px) translateY(2px) rotate(45deg);
  -ms-transform: translateX(10px) translateY(2px) rotate(45deg);
  -o-transform: translateX(10px) translateY(2px) rotate(45deg);
  transform: translateX(10px) translateY(2px) rotate(45deg);
}
.menu-button.is-active .line:nth-child(3){
  -webkit-transform: translateX(10px) translateY(-2px) rotate(-45deg);
  -ms-transform: translateX(10px) translateY(-2px) rotate(-45deg);
  -o-transform: translateX(10px) translateY(-2px) rotate(-45deg);
  transform: translateX(10px) translateY(-2px) rotate(-45deg);
}
.navigation{
  backdrop-filter: blur(11px);
  -webkit-backdrop-filter: blur(11px);
}
.hero.hero-home{
  height: calc(100vh - 60px);
  min-height: 850px;
}
.hero-slider-nav .w-slider-dot,
.testimonial-slider-nav .w-slider-dot,
.about-slider-nav .w-slider-dot{
  width: 0.6em;
  height: 0.6em;
  background-color: rgba(255,255,255,0.35);
  margin: 0 6px .5em;
}
.testimonial-slider-nav .w-slider-dot,
.about-slider-nav .w-slider-dot{
	background-color: #ACACAC;
}
.hero-slider-nav .w-slider-dot.w-active,
.testimonial-slider-nav .w-slider-dot.w-active,
.about-slider-nav .w-slider-dot.w-active{
  background-color: #A200FF;
}
.services-item:nth-child(even),
.about-slide-item:nth-child(even){
  margin-top: 70px;
}
.services-item:hover .services-caption,
.about-slide-item:hover .services-caption{
  opacity: 1;
}
.h2-padding,
.button-fat{
  vertical-align: middle;
}
.testimonial-description:before{
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 10%;
  width: 60px;
  height: 46px;
  background-image: url('https://uploads-ssl.webflow.com/5be7a377b95161803fce9927/5be7a40e6de19a586c84cdf9_quote.svg');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}
.breadcrumb-item:after{
  content: '/';
  position: absolute;
  right: -5px;
  display: inline-block;
}
.breadcrumb-item:last-child:after{
  display: none;
}
.radio-button-wrapper > input[type="radio"]{
  display: none;
}
.radio-button-wrapper > input[type="radio"] + label{
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.radio-button-wrapper > input[type="radio"] + label:before{
}
.radio-button-wrapper > input[type="radio"]:checked ~ .radio-image-wrapper > .radio-circle:before{
  content: '';
  display: block;
  position: absolute;
  width: 8px;
  height: 4px;
  top: 2px;
  left: 2px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  border-bottom: 2px solid;
  border-left: 2px solid;
  border-color: #03C756;
}
.radio-button-wrapper > input[type="radio"]:checked ~ .radio-image-wrapper > .radio-circle{
  border-color: #03C756;
}
.form-label.focused,
.form-label.valid{
  left: 0px;
  top: -50%;
  bottom: auto;
  color: #005efd;
  font-style: normal;
}
label[for=date] {
  z-index: 1;
}
.photo-item:last-child{
  margin-top: 100px;
}
.img-wrapper:hover .img-object-fit{
  opacity: 0.6;
}
.img-wrapper:hover .play-button{
  opacity: 1;
}
.cS-hidden {
  height: 1px;
  opacity: 0;
  filter: alpha(opacity=0);
  overflow: hidden;
}
.blog-list .blog-data,
.blog-list .blog-desc{
  opacity: 0;
}
.blog-list .blog-desc{
  display: none;
}
.blog-list.slick-active{
  opacity: 1;
  width: 710px;
}
.blog-list.slick-active .blog-data,
.blog-list.slick-active .blog-desc,
.blog-list.slick-active .blog-caption{
  opacity: 1;
}
.blog-list.slick-active .blog-data{
  font-size: 1em;
}
.blog-list.slick-active .blog-desc{
  display: block;
  width: 200%;
}
.blog-list-related.slick-active{
  width: 500px;
}
.slick-arrow{
  position: absolute;
  top: 73%;
  left: 10px;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #A86BC5;
  text-transform: uppercase;
  background-color: #fff;
  padding: 0 30px;
  background-image: url('https://uploads-ssl.webflow.com/5be7a377b95161803fce9927/5bebae7db5b68a7270f888cc_arrow-left.svg');
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: 16px;
}
.slick-next{
  right: 10px;
  left: auto;
  background-image: url('https://uploads-ssl.webflow.com/5be7a377b95161803fce9927/5bebae7da8138a640f352ba8_arrow-right.svg');
  background-position: 100% 50%;
}
.blog-related-wrapper .slick-arrow{
  top: 85%;
}
/*Microsite*/
.micro-nav{
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.micro-radio > input[type="checkbox"]{
  display: none;
}
.micro-radio > input[type="checkbox"] + label{
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.micro-radio > input[type="checkbox"] + label:before{
}
.micro-radio > input[type="checkbox"]:checked ~ .radio-image-wrapper > .radio-circle:before{
  content: '';
  display: block;
  position: absolute;
  width: 8px;
  height: 4px;
  top: 2px;
  left: 2px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  border-bottom: 2px solid;
  border-left: 2px solid;
  border-color: #03C756;
}
.micro-radio > input[type="checkbox"]:checked ~ .radio-image-wrapper > .radio-circle{
  border-color: #03C756;
}
.checkout-title:after{
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 4.5px 0 4.5px;
  border-color: #ffffff transparent transparent transparent;
  display: block;
  position: absolute;
  top: 50%;
  right: 10px;
}
.checkout-resume{
  width: 100%;
  border-collapse: collapse;
}
.checkout-resume td,
.checkout-resume th {
  border: 1px solid #ffffff;
}
.checkout-resume tr > td{
  padding: 10px 18px 10px 35px;
  font-weight: 700;
}
.checkout-resume tr > td > span{
  font-weight: 400;
}
.checkout-resume tr > td:last-child{
  width: 150px;
  padding: 10px 35px 10px 18px;
  text-align: right;
}
.checkout-resume tr {
  color: #000000;
}
.checkout-resume tr.checkout-active {
  color: inherit;
}
.checkout-resume tr:first-child th {
  border-top: 0;
}
.checkout-resume tr td:first-child,
.checkout-resume tr th:first-child {
  border-left: 0;
}
.checkout-resume tr td:last-child,
.checkout-resume tr th:last-child {
  border-right: 0;
}
.button-icon,
.button-text,
.micro-button{
  vertical-align: middle;
}
div.wpcf7 .ajax-loader {
	display: none;
}
.wpcf7-display-none {
  display: none !important;
}
@media (max-width: 1440px){
  .footer-25._2-columns {
    display:none;
  }
}
@media (max-width: 1024px){
  .testimonial-description:before{
    left: 0;
  }
  .photo-item:last-child {
    margin-top: 60px;
  }
  .blog-list.slick-active{
    width: 600px;
  }
  .blog-list.slick-active .blog-desc{
    display: block;
    width: 100%;
  }
  .slick-arrow{
    top: 69%;
    left: 40px;
  }
  .slick-next{
    right: 40px;
    left: auto;
  }
}
@media (max-width: 767px){
  .testimonial-description:before{
    left: 0;
    top: 0;
    width: 45px;
    height: 35px;
  }
  .services-item:nth-child(even),
  .about-slide-item:nth-child(even){
    margin-top: 0;
  }
  .blog-list-related.slick-active{
    width: 400px;
  }
  .blog-list.slick-active{
    width: 400px;
  }
  .micro-nav{
    position: relative;
  }
  #form-services .radio-circle:before{
    width: 14px;
    height: 7px;
    top: 8px;
    left: 7px;
  }
  #form-services .slick-arrow,
  #list-services .slick-arrow{
    display: none!important;
  }
  #form-services .slick-current.slick-active,
  #list-services .slick-current.slick-active{
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  #list-services .slick-current.slick-active .services-caption{
    opacity: 1;
  }
  .photo-item:last-child {
    margin-top: 0;
  }
}
@media (max-width: 479px){
  .hero.hero-home{
    height: calc(80vh - 60px);
    min-height: auto;
  }
  .blog-list-related.slick-active{
    width: 327px;
  }
  .blog-list.slick-active{
    width: 327px;
  }
  .slick-arrow{
    top: 73%;
    left: 20px;
  }
  .slick-next{
    right: 20px;
    left: auto;
  }
}

p.attachment { display: none !important; }

.testimonial-slider-item.w-slide{
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
}
.testimonial-slider-item.w-slide.testimonial-active{
  max-height: 1000px;
}
